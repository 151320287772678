// pdf utilites
export const b64toBlob = (b64Data : string, contentType : string, sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

export const openPdfSpec = (pdfString: string) => {
  const pdf = b64toBlob(pdfString, "application/pdf");
  const pdfUrl = URL.createObjectURL(pdf);
  const pdfWindow = window.open(pdfUrl);
};

export const openPdf = (fileName: string) => {
  const pdfUrl = `/content/${fileName}.pdf`; // Direct URL to the PDF file

  // Open the PDF in a new tab
  const pdfWindow = window.open(pdfUrl, "_blank");

  if (!pdfWindow) {
    console.error("Failed to open the PDF in a new tab.");
  }
};

export const downloadPDF = async (fileName) => {
    try {
      // Construct the URL to the PDF in the public folder
      const pdfUrl = `/content/${fileName}.pdf`; // Adjust the path as necessary

      // Create a link element
      const a = document.createElement("a");
      a.href = pdfUrl;
      a.download = `${fileName}.pdf`; // Set the download file name
      document.body.appendChild(a);
      a.click(); // Programmatically click the link to trigger the download
      document.body.removeChild(a); // Clean up

      return;
    } catch (error) {
      console.error("Error downloading document:", error);
      return;
    }
  };