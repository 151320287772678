/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Transition } from "@headlessui/react";
import { BiX as XIcon} from "react-icons/bi";
import React, { useState, useMemo, useEffect } from "react";
import clsx from "clsx";
import { Fragment, useRef } from "react";
import { HiXCircle } from "react-icons/hi";
//import uuid from "react-uuid"; 

const KeyValueListBox: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  defaultValue:string;
  keyset:string;
  value:{key:string,value:string}|null;
  optionList: string[];
  setValue: (e) => void;
  className?: string;
}> = ({ isOpen, onClose, keyset, value, setValue, className, defaultValue, optionList}) => {
  //const closeButtonRef = useRef(null);
  const [search, setSearch] = useState<string>("")
  const searchedList = useMemo(()=> optionList.filter(opt => search !== ""? opt.toUpperCase().includes(search.toUpperCase()): true), [search, optionList]);
  const ref = useRef(null);

  //auto focus on search box
  useEffect(() => {
    const el = document.getElementById('search');
    if(el){
      el.focus();
    }
  }, []);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="absolute inset-0 overflow-hidden"
        open={isOpen}
        onClose={onClose}
        //initialFocus={closeButtonRef}
      >
        <div className="absolute inset-0 overflow-hidden">
          {/* <Dialog.Overlay className="absolute inset-0 slideover" /> */}

          <div className="fixed inset-y-0 right-0 sm:pl-10 max-w-full md:w-3/5 lg:w-1/2 xl:w-1/3 flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-200 sm:duration-300"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-200 sm:duration-300"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div
                className={clsx(
                  "w-screen",
                  className ? "md:max-w-2xl lg:max-w-3xl": null,
                )}
              >
                <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                  <div className="min-h-0 flex-1 flex flex-col overflow-hidden ">
                    <div className="flex flex-row bg-red-500 px-3 py-2 justify-between">
                      <Dialog.Title className="text-lg font-medium text-white">
                        {`Selected ${defaultValue}: ${value? value.value: defaultValue}`}
                      </Dialog.Title>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          className="rounded-md text-white hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-white"
                          onClick={() => onClose()}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    {/* content below here */}
                    <div className="flex flex-row bg-white h-full overflow-hidden">
                      <div className={"relative flex flex-col w-full overflow-x-hidden overflow-y-auto space-y-1"}>
                        <div className={"flex flex-col w-full space-y-1 py-2 px-2 bg-white sticky top-0 shadow border-b"}>
                            <div className="w-full flex flex-row align-center items-center text-center rounded-md border bg-white shadow">
                              <label
                                htmlFor="search"
                                className={clsx("block text-sm font-medium leading-5 flex flex-row px-2", search !== "" ? "text-green-500" : null)}
                              >
                                Search:
                              </label>
                              <input
                                  ref={ref}
                                  id={"search"}
                                  type="text"
                                  autoComplete="false"
                                  className="form-input block w-fit grow sm:text-sm sm:leading-5 p-2 border-x"
                                  value={search}
                                  onChange={e => setSearch(e.target.value)}
                                />
                                <button className={"justify-center text-center hover:text-red-500 mx-2"} onClick={() => setSearch("")}><HiXCircle size={28}/></button>
                            </div>

                          <div className="w-full flex flex-row space-x-2 justify-end">
                            <button className={"flex flex-row px-2 border bg-white justify-center text-center hover:bg-sky-200 rounded-md shadow"} key={-1} onClick={() => setValue(null)}><p>Clear Filter/ Set Default</p></button>
                            <button className="flex flex-row px-2 border bg-white justify-center text-center hover:bg-red-200 rounded-md shadow" key={-2} onClick={onClose}><p>Close</p></button>
                          </div>
                        </div>

                        {searchedList.map((opt, i) => 
                          <button className={clsx("mx-2 flex flex-row px-2 border rounded-md shadow", value !== null && opt === value.value? "bg-sky-500 text-white hover:bg-gray-200": "bg-white hover:bg-sky-200")} key={`${opt}-${i}`} onClick={() => setValue({key:keyset, value:opt})}>{opt}</button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default KeyValueListBox;
