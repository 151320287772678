import React, {
  useRef,
} from "react";
import clsx from "clsx";
import {FaFacebook, FaFileDownload, FaFileUpload, FaGithub, FaInstagram, FaLinkedin} from "react-icons/fa";
import { downloadPDF, openPdf } from "../../utilites/pdfUtilites";

const BioPage: React.FC<
  React.PropsWithChildren<
    React.PropsWithChildren<{
      fullScreenIn?: boolean;
    }>
  >
> = ({ fullScreenIn }) => {
  
  const resume = "Rittir Frankowski Resume";
  const cv = "Rittir Frankowski Cv";

  
  const langs = [
    "TypeScript",
    "C#",
    "Ruby",
    "C++",
    "C",
    "CSS",
    "HTML",
    "MySQL",
    "MSSQL",
    "MATLAB",
    "Haskell",
    "Processing",
    "Python",
  ];

  const frames: string[] = [
    "React",
    "Tailwind",
    ".Net",
    "Winforms/WPF",
    "Firebase",
    "HighCharts",
    "QT",
    "Flutter",
    "Arduino",
    "Node.js",
  ];

  // 3D Software array
  const dddSoftware: string[] = [
    "Fusion",
    "Maya",
    "Arnold",
    "SolidWorks",
    "Blender",
  ];

  // 2D Software array
  const ddSoftware: string[] = [
    "Illustrator",
    "InDesign",
    "Photoshop",
    "Lightroom",
    "Aftereffects",
    "Premier",
    "Inkscape",
    "Procreate",
    "Office Suite",
  ];

  // Design/Prototyping array
  const prototyping: string[] = [
    "3D printing",
    "Metal working",
    "Welding",
    "CNC laser",
    "Fixturing",
    "Concept art",
    "Micro controllers",
    "carpentry",
  ];

  const socialLinks = [
    {
      href: "https://www.linkedin.com/in/rittir-frankowski-244ba1140/",
      icon: <FaLinkedin size={22} />,
      bgColor: "text-rose-500",
      hoverColor: "hover:text-rose-900 transition duration-200 bg-slate-900",
    },
    {
      href: "https://github.com/CrittirsCreations",
      icon: <FaGithub size={22} />,
      bgColor: "text-cyan-500",
      hoverColor: "hover:text-cyan-900 transition duration-200 bg-slate-900",
    },
    {
      href: "https://www.instagram.com/crittirscreations/",
      icon: <FaInstagram size={22} />,
      bgColor: "text-amber-500",
      hoverColor: "hover:text-amber-900 transition duration-200 bg-slate-900",
    },
    {
      href: "https://www.facebook.com/rittir.frankowski",
      icon: <FaFacebook size={22} />,
      bgColor: "text-white",
      hoverColor: "hover:text-gray-500 transition duration-200 bg-slate-900",
    },
  ];

  const skills = [
    {
      title: "Languages",
      content: langs,
      color: "green",
    },
    {
      title: "Frameworks / Libraries",
      content: frames,
      color: "fuchsia",
    },
    {
      title: "2D Software",
      content: ddSoftware,
      color: "rose",
    },
    {
      title: "3D Software",
      content: dddSoftware,
      color: "cyan",
    },
    {
      title: "Prototyping / Design",
      content: prototyping,
      color: "amber",
    },
  ];
  // <img
  //   src="/Content/BearArtboard 1.svg"
  //   alt="My Icon"
  //   className="h-48 w-48 filter invert-0 sepia-0 saturate-100 hue-rotate-180"
  //   style={{
  //     filter:
  //       "invert(100%) sepia(100%) saturate(0%) hue-rotate(200deg)",
  //   }} // Custom color via filter

  const projects = [
    {
      id: 0,
      thumbnail: `/Content/image.png`,
      title: "My Portfolio website",
      desciption:
        "Made with typescript react and tailwind css, checkout the site details page for more information on packages, libraries, and techniques i used",
      link: "/",
    },
    {
      id: 1,
      thumbnail: `/Content/image.png`,
      title: "Branson TDR15 Automation",
      desciption:
        "Standalone application to automate programing and execution of cleaning procedures on the old TDR-15",
      link: "",
    },
    {
      id: 3,
      thumbnail: `/Content/image.png`,
      title: "Go Room",
      desciption:
        "six panel live updating production display, with touchscreen and custom application for entering and updating tickets/tasks in the EQMS System",
      link: "",
    },
    {
      id: 2,
      thumbnail: `/Content/image.png`,
      title: "How to make a lamp",
      desciption:
        "a simple project, a freindy contest between freinds, a never ending quest for illumination",
      link: "",
    },

    {
      id: 4,
      thumbnail: `/Content/image.png`,
      title: "The House",
      desciption:
        "A forever home, forever in the making. Built for my parents, our familiy this house is a testament to imperfect woodworking and the Wabi-sabi way",
      link: "",
    },
    {
      id: 5,
      thumbnail: `/Content/image.png`,
      title: "Raquet river map",
      desciption:
        "a detailed map and treasure hunt created during a summer spent living in a shack on the river",
      link: "",
    },
  ];

  const makeSkillSection = (x: {
    title: string;
    content: string[];
    color: string;
  }) => {
    const colorClass = {
      green: "text-green-300 bg-green-900 border-green-500",
      fuchsia: "text-fuchsia-300 bg-fuchsia-900 border-fuchsia-500",
      rose: "text-rose-300 bg-rose-900 border-rose-500",
      cyan: "text-cyan-300 bg-cyan-900 border-cyan-500",
      amber: "text-amber-300 bg-amber-900 border-amber-500",
    };

    const colorClassTitles = {
      green: "text-green-300",
      fuchsia: "text-fuchsia-300",
      rose: "text-rose-300",
      cyan: "text-cyan-300",
      amber: "text-amber-300",
    };

    return (
      <>
        <h2
          className={`mt-2 flex flex-row text-2xl font-bold ${
            colorClassTitles[x.color]
          } w-full lg:w-1/2 lg:mx-auto`}
        >
          {x.title}
        </h2>
        <div className="mt-1 flex flex-wrap gap-2 items-center place-content-start w-full lg:w-1/2 lg:mx-auto">
          {x.content.map((y) => (
            <div
              className={`pb-0.5 flex items-center justify-center border rounded-md ${
                colorClass[x.color]
              } shadow-md px-2`}
              key={y}
            >
              <p>{y}</p>
            </div>
          ))}
        </div>
      </>
    );
  };

  const topRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);
  const projectsRef = useRef<HTMLDivElement>(null);
  const scrollToTop = () => {
    if (topRef.current) {
      topRef.current.scrollTo({
        top: 0,
        behavior: "smooth", // Enables smooth scrolling
      });
    }
  };

  const scrollToLoc = (loc: React.MutableRefObject<HTMLDivElement | null>) => {
    if (topRef.current && loc.current) {
      topRef.current.scrollTo({
        top: loc.current.offsetTop, // Scroll to the top position of the referenced element
        behavior: "smooth", // Enables smooth scrolling
      });
    }
  };

  return (
    <div
      className={clsx(
        "border-t border-amber-300 relative flex w-full divide-black flex-col overflow-hidden h-screen justify-center items-center align-center bg-slate-900"
      )}
    >
      {/* main */}
      <div
        ref={topRef}
        className="flex flex-col w-full overflow-y-auto scrollbar-hide overflow-x-hidden text-white"
      >
        {/* bio and skills */}
        <div
          className="p-2 pb-6 flex flex-col justify-center text-left"
          id="about"
        >
          <h2 className="flex flex-row text-4xl font-bold text-white mx-auto">
            Rittir Frankowski
          </h2>
          <h2 className="flex flex-row text-white text-center mx-auto space-x-1">
            <p>Design</p>
            <span className="text-white">●</span> {/* Separator */}
            <p>Develop</p>
            <span className="text-white">●</span> {/* Separator */}
            <p>Engineer</p>
            <span className="text-white">●</span> {/* Separator */}
            <p>Art</p>
          </h2>

          {/* links */}
          <div className="mt-2 flex flex-wrap gap-2 p-1 items-center place-content-center w-full lg:w-1/2 lg:mx-auto bg-slate-600 rounded-md shadow-md">
            <button
              className={`flex-1 flex flex-row text-gray-500 p-2 rounded-md hover:text-teal-500 hover:bg-slate-800 transition duration-200 bg-slate-900 shadow-md justify-center items-center text-center`}
              onClick={() => scrollToLoc(contactRef)}
            >
              <p className="">Contact</p>
            </button>

            {/* resume */}
            <button
              className={`flex flex-row p-2 text-green-500 rounded-md bg-slate-900 items-center shadow-md space-x-1`}
            >
              <p>Resume</p>
              <FaFileDownload
                size={22}
                className="flex flex-none hover:text-green-900 transition duration-200"
                onClick={() => downloadPDF(resume)}
              />
              <FaFileUpload
                size={22}
                className="flex flex-none hover:text-green-900 transition duration-200"
                onClick={() => openPdf(resume)}
              />
            </button>

            {/* cv */}
            <button
              disabled={true}
              className={`flex flex-row p-2 text-fuchsia-500 rounded-md bg-slate-900 items-center shadow-md space-x-1`}
            >
              <p>CV</p>
              <FaFileDownload
                size={22}
                className="flex flex-none hover:text-fuchsia-900 transition duration-200"
                onClick={() => downloadPDF(cv)}
              />
              <FaFileUpload
                size={22}
                className="flex flex-none hover:text-fuchsia-900 transition duration-200"
                onClick={() => openPdf(cv)}
              />
            </button>

            {/* links act */}
            {socialLinks.map((link, index) => (
              <a
                key={index}
                href={link.href}
                target={"_blank"}
                rel="noopener noreferrer"
                className={`flex flex-row ${link.bgColor} p-2 rounded-md ${link.hoverColor} items-center shadow-md`}
              >
                {link.icon}
              </a>
            ))}

            <button
              className={`flex-1 flex flex-row text-gray-500 p-2 rounded-md hover:text-teal-500 hover:bg-slate-800 transition duration-200 bg-slate-900 shadow-md justify-center items-center text-center`}
              onClick={() => scrollToLoc(projectsRef)}
            >
              <p className="">Projects</p>
            </button>
          </div>

          <h2 className="mt-2 flex flex-row text-2xl font-bold text-white w-full lg:w-1/2 lg:mx-auto">
            About Me
          </h2>
          <p className="flex flex-wrap gap-2 items-center place-content-start lg:place-content-center w-full lg:w-1/2 lg:mx-auto">
            I'm a full-stack web developer and software engineer with a passion
            for creating beautiful and functional websites, applications,
            gadgets, and gizmos.
          </p>

          {/* skills */}
          {skills.map((x) => makeSkillSection(x))}
        </div>

        {/* Projects Section */}
        <div
          ref={projectsRef}
          className="py-4 px-2 flex flex-col justify-center text-left shadow-lg bg-slate-950 text-white"
          id="projects"
        >
          <h2 className="flex flex-row w-full text-2xl font-bold mx-auto">
            Projects
          </h2>
          <h2 className="flex font-semibold flex-row text-left mx-auto lg:mx-0 mb-2">
            Checkout the gallery and downloads pages for more work
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {/* Project Card */}
            {projects.map((x) => (
              <div className="flex flex-col bg-slate-900 p-4 rounded-lg shadow-lg">
                <img
                  src="https://via.placeholder.com/300"
                  alt="https://via.placeholder.com/300"
                  onError={(e) =>
                    (e.currentTarget.src = "/Content/fallback.png")
                  }
                  loading="lazy"
                  className="w-full h-48 object-cover rounded-lg shadow-lg"
                />

                <h3 className="none text-left mt-1 text-xl font-semibold text-amber-500">
                  {x.title}
                </h3>

                <p className="flex-1 text-left mt-1 text-gray-600">
                  {x.desciption}
                </p>

                <div className="flex flex-row w-full justify-end items-center">
                  <a
                    href={x.link}
                    target="_self"
                    className="text-gray-600 rounded hover:text-teal-600 transition duration-300 transition"
                  >
                    more...
                  </a>
                </div>
              </div>
            ))}
            {/* end */}
          </div>
        </div>

        {/* Contact Section */}
        <div
          ref={contactRef}
          className="p-2 container mx-auto text-center"
          id="contact"
        >
          <h2 className="mt-2 text-2xl font-bold text-white mx-auto">
            Contact Me
          </h2>
          <p className="gap-2 items-center place-content-start lg:place-content-center w-full lg:w-1/2 lg:mx-auto">
            For questions, comments, collaborations, and commissions
          </p>

          <form className="max-w-lg mx-auto">
            <div className="mb-4">
              <input
                type="text"
                placeholder="Your Name"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg"
              />
            </div>
            <div className="mb-4">
              <input
                type="email"
                placeholder="Your Email"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg"
              />
            </div>
            <div className="mb-4">
              <textarea
                placeholder="Your Message"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                rows={5}
              ></textarea>
            </div>
            <button
              type="submit"
              className="px-6 py-3 bg-teal-500 text-white font-semibold rounded hover:bg-teal-600 transition duration-300"
            >
              Send Message
            </button>
          </form>
        </div>

        {/* Footer */}
        <footer className="flex flex-row justify-center items-center bg-slate-950 text-white text-center px-2">
          <img
            src="/Content/TreeArtboard 1.svg"
            alt="My Icon"
            className="flex flex-col h-14 w-14 transform scale-x-[-1] filter invert-0 sepia-0 saturate-100 hue-rotate-180"
            style={{
              filter:
                "invert(100%) sepia(100%) saturate(0%) hue-rotate(200deg)",
            }} // Custom color via filter
          />

          <div className="flex flex-col space-y-1">
            <button
              className="text-white hover:text-amber-500 transition duration-300"
              onClick={() => scrollToTop()}
            >
              Back to top
            </button>
            <p>
              &copy; {new Date().getFullYear()} Rittir Frankowski. All rights
              reserved.
            </p>
          </div>
          <img
            src="/Content/TreeArtboard 1.svg"
            alt="My Icon"
            className="flex flex-col h-14 w-14 filter invert-0 sepia-0 saturate-100 hue-rotate-180"
            style={{
              filter:
                "invert(100%) sepia(100%) saturate(0%) hue-rotate(200deg)",
            }} // Custom color via filter
          />
        </footer>
      </div>
    </div>
  );
};

export default BioPage;
