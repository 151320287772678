import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  KeyboardEvent,
  createContext,
  useCallback,
  useContext,
} from "react";
import axios from "axios";
import ParentApi from "../../utilites/Api/ParentApi";
import clsx from "clsx";
import { useRecoilState } from "recoil";
import { ReactUserAccount } from "../../utilites/Globals/Atoms";
import RouterUtility from "../../utilites/routerUtils";
import {
  BiPlus,
  BiMinus,
  BiMessageAdd,
  BiMessageAltAdd,
  BiMessageAltX,
  BiMessageAltCheck,
  BiMessageAltDots,
  BiTrash,
  BiSave,
} from "react-icons/bi";
import { object } from "prop-types";
import {
  getColorConverter,
  colorLookUp,
  reverseLookup,
} from "../../utilites/Decoders/ColorConverter";
import { BsArrow90DegUp, BsBoxArrowUpRight, BsFillArrowUpRightCircleFill } from "react-icons/bs";

const defaultUrlParamKeys = {
  isValid: "false",
  userLocation: "main",
};

const Welcome: React.FC<
  React.PropsWithChildren<
    React.PropsWithChildren<{
      fullScreenIn?: boolean;
    }>
  >
> = ({ fullScreenIn }) => {

  return (
    <div
      className={clsx(
        "relative flex w-full divide-black flex-col overflow-hidden h-screen justify-center items-center align-center bg-slate-800"
      )}
    >
      {/*center window*/}
      <div
        className={clsx(
          "flex flex-col flex-none relative overflow-hidden bg-amber-600 rounded-md shadow-xl border border-amber-800"
        )}
      >
        {/* title */}
        <label
          className={clsx(
            "font-bold flex flex-row font-medium text-lg text-amber-800 p-2 bg-amber-300 border-b border-amber-800"
          )}
        >
          Welcome
        </label>

        {/* content */}
        <div
          className={clsx("flex text-amber-800 text-sm flex-col text-left p-2")}
        >
          <p className="font-medium font-bold text-amber-300">
            Please enjoy your visit and report any bugs
          </p>

          <p className="font-medium font-bold text-amber-300">
            CrittirsCreations@gmail.com
          </p>
        </div>

        {/* buttons */}
        <div className={"flex flex-row w-full space-x-2 p-2 justify-end"}>
          <a
            href={"/Bio"}
            target={"_self"}
            rel="noopener noreferrer"
            className={"flex w-1/4 justify-center shadow bg-amber-300 font-semibold hover:bg-amber-500 text-amber-800 border border-amber-800 rounded-md"}
          >
            Bio
          </a>
          <button className="flex w-1/4 justify-center shadow bg-amber-300 font-semibold hover:bg-amber-500 text-amber-800 border border-amber-800 rounded-md">
            Fun
          </button>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
