import React, { useMemo } from "react";
import { Outlet, matchPath } from "react-router";
import { useState, useEffect } from "react";
import { Webpages } from "../../utilites/Globals/Globals";
import {
  BiUserCircle,
  BiArrowFromLeft,
  BiX,
  BiFolder,
  BiFolderOpen,
  BiMenu,
} from "react-icons/bi";
import { useRecoilState } from "recoil";
import { CurrentUser, ReactUserAccount } from "../../utilites/Globals/Atoms";
import UserProfileSlideover from "../../utilites/slideOvers/UserProfileSlideover";
import clsx from "clsx";
//import uuid from "react-uuid";
import localforage from "localforage";
import { UserAccount, MODE_D } from "../../utilites/Decoders/Decoders";
import RouterUtility from "../../utilites/routerUtils";
import { User, UserCredential } from "firebase/auth";
import { useAuth } from "../../utilites/Authprovider";
import { RiDropdownList } from "react-icons/ri";


const Root: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<{}>>
> = () => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [openUserProfile, setOpenUserProfile] = useState<boolean>(false);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [pageGroups, setPageGroups] = useState<
    { group: string; open: boolean }[]
  >([]);
  const [availPages, setAvailPages] = useState<any>({});
  const routerUtility = new RouterUtility();
  const { user } = useAuth();
  
  
  const loadUser = async (act: User | null) => {
    //console.log("current User", act);
    if (act !== null) {
      setCurrentUser(act);
      const lastPage = await routerUtility
        .getLocal("lastPage", false)
        .then((e) => (e !== null ? e : "/"));

      //console.log("clearing localstorage WA");
      //localforage.clear();

      if ((routerUtility.location.pathname as string) === "/") {
        routerUtility.navigateTo(lastPage, null, { replace: true });
      }
    } else {
      setCurrentUser(null);
      //console.log("clearing localstorage NA");
      localforage.clear();

      const pathstr = routerUtility.location.pathname as string;
      // console.log("curloc", pathstr);
      // let page = Webpages.find((x) => x.path === pathstr);
      //if (page && page.permissionLevel > 0) {
      // if(pathstr !== "/" ){  //-block unsignedin users
      //   routerUtility.navigateTo("/");
      // }
      //}
    }
  };

  useEffect(() => {
    loadUser(user);
  }, [user]);


  //if you are not on the index page, log the page as your last visted page
  useEffect(() => {
    if ((routerUtility.location.pathname as string) !== "/") {
      const strLoc = routerUtility.location.pathname as string;
      routerUtility.setCache(undefined, { name: "lastPage", data: strLoc });
      // localforage.setItem('lastPage', routerUtility.location.pathname);
    }
  }, [routerUtility.location]);

  //generate availible webpages
  useEffect(() => {
    let aPages: MODE_D[] = [];

    if (currentUser === null) {
      aPages = Webpages.filter(
        (page) => page.active && page.ID >= 0 && page.permissionLevel === 0
      );
    } else {
      aPages = Webpages.filter(
        (page) =>
          page.active &&
          page.ID >= 0 
          //&& page.permissionLevel <= 0 + currentReactAccount.PERMISSIONS
      );
    }

    const groups = Array.from(new Set(aPages.map((x) => x.group)))
      .sort((a, b) => -a.localeCompare(b))
      .map((x) => ({ group: x, open: true }))
      .reverse();
    setPageGroups(groups);

    const mapped = {};

    groups.forEach((x) => {
      mapped[x.group ? x.group : "Def"] = aPages
        .filter((y) => y.group === x.group)
        .sort((a, b) => a.mode.localeCompare(b.mode));
    });
    setAvailPages(mapped);
  }, [currentUser]);

  const handleGroupClick = (group: { group: string; open: boolean }) => {
    setPageGroups((oldData) => {
      const newData = [...oldData];
      const idx = newData.findIndex((x) => x.group === group.group);
      newData[idx] = { ...group, open: !group.open };
      return [...newData];
      //oldData.find(x => x.group === group.group).open = !group.open;
      //return [...oldData]
    });
  };

  return (
    <div className="relative flex flex-col overflow-hidden h-screen w-screen">
      {/* user profile init */}
      {/* <UserProfileSlideover
        isOpen={openUserProfile}
        onClose={() => setOpenUserProfile(false)}
        onSubmit={() => console.log("profile submitted: ")}
      /> */}

      {/* title bar*/}
      <header className={"flex h-8 flex-row bg-slate-900 flex-none justify-between w-full overflow-hidden"}>
        {/* left side */}
        <div className="flex flex-col flex-1 mt-2">
          {/* tittle
          <p className="flex flex-row text-amber-500 font-bold px-2">
            CRittirsCreations
          </p> */}

          {/* dropdwn */}
          <button
            className={clsx(
              "ml-4 space-x-1 flex flex-row rounded-t-md items-center font-semibold px-2 w-48 flex-none",
              !openMenu
                ? "px-1 hover:bg-slate-700 text-teal-500"
                : "bg-stone-800 border-t border-x border-amber-300 text-amber-300 text-amber-300 hover:text-amber-900"
            )}
            onClick={() => setOpenMenu(!openMenu)}
          >
            {!openMenu ? (
              <>
                <p>
                  {Webpages.find(
                    (page) =>
                      matchPath(page.path, routerUtility.location.pathname) !==
                      null
                  )
                    ? Webpages.find(
                        (page) =>
                          matchPath(
                            page.path,
                            routerUtility.location.pathname
                          ) !== null
                      ).mode
                    : "Open Menu"}
                </p>
                <RiDropdownList size={20} />
              </>
            ) : (
              <>
                <BiFolderOpen  size={20} />
                <p>Available Pages</p>
                <BiX size={20} />
              </>
            )}
          </button>
        </div>

        {/*right side*/}
        {/* <div
          className="flex px-2 flex-row items-center cursor-pointer overflow-hidden"
          onClick={() => setOpenUserProfile(true)}
        >
          <p className="flex flex-none text-white text-right overflow-hidden">
            {new Date().toLocaleDateString()}
          </p>
          <p className="flex flex-none text-left text-amber-500">
            {new Date().toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: false, // Ensures 24-hour format, which removes AM/PM
            })}
          </p>

          <BiUserCircle
            size={20}
            className={clsx(
              "ml-2",
              !currentUser ? "text-teal-500" : "text-teal-500"
            )}
          />
        </div> */}
      </header>

      {/*main content*/}

      <div className="relative flex flex-row w-full h-full overflow-hidden bg-slate-900">
        {/* content */}
        <div className={"flex flex-col w-full h-full overflow-hidden"}>
          <Outlet />
        </div>

        {/* side bar */}
        {openMenu ? (
          <>
            {/* legs */}
            <div
              className={clsx(
                "bg-stone-900 absolute left-0 inset-y-12 bg-slate-900 border border-amber-300 shadow-xl rounded-md ml-2 w-52 h-7",
                openMenu ? "" : "hidden"
              )}
            />
            <div
              className={clsx(
                "bg-stone-900 absolute left-0 inset-y-1/2 bg-slate-900 border border-amber-300 shadow-xl rounded-md w-52 ml-2 h-8",
                openMenu ? "" : "hidden"
              )}
            />

            {/* sidebar main */}
            <nav className="mb-2 absolute ml-4 flex flex-col justify-between rounded-b-md h-2/3 bg-stone-800 shadow-xl overflow-x-hidden overflow-y-auto scrollbar-hide w-48 flex-none border-x border-b border-amber-300">
              {/* <ul className="w-full truncate overflow-x-hidden"> */}
              <div className="flex flex-col">
                {pageGroups.map((page) => (
                  //folder maping
                  <ul key={`${page.group}`}>
                    {page.group ? (
                      //folder name
                      <button
                        className="cursor-pointer flex flex-row text-amber-300 hover:text-amber-800 items-center px-2"
                        onClick={() => handleGroupClick(page)}
                      >
                        {page.open ? (
                          <BiFolderOpen size={20} />
                        ) : (
                          <BiFolder size={20} />
                        )}
                        <p
                          className={clsx(
                            "text-left truncate text-lg font-smeibold"
                          )}
                        >
                          {page.group}
                        </p>
                      </button>
                    ) : null}

                    {!page.group
                      ? //no group/folder
                        availPages["Def"].map((act: MODE_D) => (
                          <li
                            className="px-4 flex flex-row text-amber-300 hover:text-amber-900"
                            key={`${act.mode}`}
                          >
                            <p className="">|</p>{" "}
                            <a href={act.path}>
                              <p
                                className={clsx(
                                  "pl-4 text-white hover:text-teal-500 text-left truncate"
                                )}
                              >
                                {act.mode}
                              </p>
                            </a>
                          </li>
                        ))
                      : page.open
                      ? //grouped items
                        availPages[page.group].map((act: MODE_D) => (
                          <li
                            className="px-4 flex flex-row text-amber-300 hover:text-amber-900"
                            key={`${act.mode}`}
                          >
                            <p className="">|</p>{" "}
                            <a href={act.path}>
                              <p
                                className={clsx(
                                  "pl-4 text-white hover:text-teal-500 text-left truncate"
                                )}
                              >
                                {act.mode}
                              </p>
                            </a>
                          </li>
                        ))
                      : null}
                  </ul>
                ))}
                <li className="px-4 flex flex-row invisible">
                  <p className="text-amber-500">|</p>
                  <p
                    className={clsx(
                      "pl-4 text-white hover:text-teal-500 text-left truncate"
                    )}
                  >
                    spacer
                  </p>
                </li>
              </div>

              <img
                src="/Content/BearArtboard 1.svg"
                alt="My Icon"
                className="h-48 w-48 filter invert-0 sepia-0 saturate-100 hue-rotate-180"
                style={{
                  filter:
                    "invert(100%) sepia(100%) saturate(0%) hue-rotate(200deg)",
                }} // Custom color via filter
              />
              {/* </ul> */}
            </nav>
          </>
        ) : (
          <></>
        )}
      </div>

      {/* footer */}
      <div className="absolute rounded-tl-md bg-slate-900 text-white bottom-0 right-0 flex flex-row flex-none justify-end">
        <p className="px-2 text-xs font-small">
          By Rittir Frankowski
        </p>
      </div>
    </div>
  );
};

export default Root;
