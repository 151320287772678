import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  KeyboardEvent,
  createContext,
  useCallback,
  useContext,
} from "react";
import axios from "axios";
import ParentApi from "../../utilites/Api/ParentApi";
import clsx from "clsx";
import { useRecoilState } from "recoil";
import { ReactUserAccount } from "../../utilites/Globals/Atoms";
import RouterUtility from "../../utilites/routerUtils";
import {
  BiPlus,
  BiMinus,
  BiMessageAdd,
  BiMessageAltAdd,
  BiMessageAltX,
  BiMessageAltCheck,
  BiMessageAltDots,
  BiTrash,
  BiSave,
  BiX,
  BiSolidBusiness,
  BiHistory,
  BiArrowBack,
} from "react-icons/bi";
import { object } from "prop-types";
import {
  getColorConverter,
  colorLookUp,
  reverseLookup,
} from "../../utilites/Decoders/ColorConverter";
import { fireDb, fireStore } from "../../utilites/firebase";
import { onValue, ref, get } from "firebase/database";
import { ref as storeRef, getDownloadURL, getBlob } from "firebase/storage";
import { useAuth } from "../../utilites/Authprovider";
import { DocControl_Standard_Decoder, attachedSupplier } from "../../utilites/Decoders/Decoders";
import { FaArrowDown, FaArrowUp, FaXbox } from "react-icons/fa";
import sortBy from "sort-by";
import SupModal from "../Private/supModal";

const defaultUrlParamKeys = {
  isValid: "false",
  userLocation: "main",
};

const DocBox: React.FC<
  React.PropsWithChildren<
    React.PropsWithChildren<{
      fullScreenIn?: boolean;
    }>
  >
> = ({ fullScreenIn }) => {
  //standard vars
  const DocTypes = [
    "Standards",
    "Procedures"
  ]
  const routerUtility = new RouterUtility();
  const [sourcePath, setSourcePath] = useState<string>(routerUtility.urlParams["DocumentType"] === ":DocumentType"
        ? DocTypes[0]: routerUtility.urlParams["DocumentType"]);
  const [docList, setDocList] = useState<DocControl_Standard_Decoder[]>([]);
  const [searchString, setSearchString] = useState<string>("");
  const [defaultSort, setDefaultSort] = useState<string>("Doc_Num");
  const [reverse, setReverse] = useState<boolean>(false);
  const [obsSelected, setObsSelected] = useState<DocControl_Standard_Decoder|null>(null);
  const [supSelected, setSupSelected] = useState<DocControl_Standard_Decoder|null>(null);
  const [supFilter, setSupFilter] = useState<string|null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [attSups, setAttSups] = useState<attachedSupplier[]>([]);


  useEffect(() => {
      //fetch content combines the blogposts with their associated images
      const FetchContent = async (temp: DocControl_Standard_Decoder[]) => {
        try {
          //pull attacheted files
          {
            //pull in files
            // for (var x = 0; x < temp.length; x++) {
            //   if (typeof temp[x].content === "string") {
            //     const retrievedContent = await getDownloadURL(
            //       storeRef(fireStore, temp[x].content as string)
            //     );
            //     temp[x].content = retrievedContent;
            //   }
            // }
          }

          //pull attached suppliers
          const tempSups: attachedSupplier[] = [];

          const supPath = ref(fireDb, "AttSup");

          try {
            const snapshot = await get(supPath);

            if (snapshot.exists()) {
              const data = snapshot.val();
              tempSups.push(
                ...Object.values(data).map((x) => x as attachedSupplier)
              );
            }
          } catch (error) {
            console.error("Error fetching attached suppliers:", error);
          }

          console.log(tempSups);
          
          setAttSups(tempSups);

          temp = temp.map((x) => {
            return {
              ...x,
              RevisionNotes: `${temp.filter((y) => y.Doc_Num === x.Doc_Num).length < 2? "N" : "Y"},${tempSups.findIndex(y => y.Doc_Num === x.Doc_Num) !== -1? "Y": "N"}`,
            }
          });

          console.log(`recived: ${sourcePath}`, temp);
          setDocList(temp);
        } catch (e) {
          console.log("fetch blog post content failed!", e);
        }
      };

      if (!DocTypes.includes(sourcePath)) {
        console.log("invalid source", sourcePath);
        return;
      }

      const connectionPath = ref(fireDb, sourcePath);
      return onValue(connectionPath, (snapshot) => {
        const data = snapshot.val();
        console.log("raw res", data);
        if (snapshot.exists()) {
          let temp = Object.values(data).map((x) => x as DocControl_Standard_Decoder);
          if (temp.length > 0) {
            FetchContent(temp);
          }
        }
      });
    }, [sourcePath]);

    const changeDocTypes = (key:string) => {
      if (!DocTypes.includes(sourcePath)) {
        console.log("invalid source", sourcePath);
        return;
      }

      setSupFilter(null);
      setSupSelected(null);
      setObsSelected(null);

      setSourcePath(key);
      routerUtility.linkTo(null, `/Documents/${key}`);
    }

    const downloadDocument = async (x: DocControl_Standard_Decoder) => {
      if(!DocTypes.includes(sourcePath)){
        console.log("invalid source", sourcePath)
        return;
      }

      try {
        const blob = await getBlob(storeRef(fireStore, `${sourcePath}Files/${x.File_Path}`));

        // Create a blob URL and initiate download
        const blobUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobUrl;
        a.download = `${x.Doc_Name}.pdf`; // Set the download file name
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        // Clean up the blob URL after download
        URL.revokeObjectURL(blobUrl);
        return;
      } catch (error) {
        console.error("Error fetching document:", error);
        return;
      }
    };

    const getAttachedDoc = async (x:DocControl_Standard_Decoder) => {
      if (!DocTypes.includes(sourcePath)) {
        console.log("invalid source", sourcePath);
        return;
      }

      const mode = "pdf";
      const retrievedContent = await getDownloadURL(
        storeRef(fireStore,`${sourcePath}Files/${x.File_Path}`)
      );

      const tempDoc = retrievedContent;
      switch (mode) {
        // case "preview":
        //     console.log(response);
        //     const bText = response.data;
        //     setExportedDocument(bText);
        //     updateUrl("userLocation", "exporting");
        //     break;
        // case "xlsx":
        //     // create "a" HTML element with href to file & click
        //     const href = URL.createObjectURL(response.data);
        //     const link = document.createElement('a');
        //     link.href = href;
        //     //link.setAttribute('download', 'file.xlsx'); //or any other extension
        //     link.setAttribute('download', 'file.html'); //or any other extension
        //     document.body.appendChild(link);
        //     link.click();

        //     // clean up "a" element & remove ObjectURL
        //     document.body.removeChild(link);
        //     URL.revokeObjectURL(href);
        //     break;
        case "pdf":
         const href = retrievedContent;
         window.open(href, "_blank");
         break;
        default:
          break;
      }
    };

    const abreivateNotes = (x:string) => { 
      if(!x){
        return "NA"
      }

      const ti = x.indexOf("Title");
      const sc =  x.indexOf("Scope");

      if(sc !== -1){
        if(ti !== -1){
          return x.substring(
            ti + 6,
            sc
          )?.trim()
        }else{
          return x.substring(sc + 6)?.trim();
        }
    }else{
        return x;
      }
    }
 
    const useDocs = useMemo(() => {
      let tempList = docList.filter(
        (x) =>
          (x.Doc_Status == "Active" || x.RevisionNotes.split(",")[0] === "N") && x.Revision !== "NR");

      if(supFilter !== null){
        const accList = attSups.filter(x => x.AttachPath === supFilter);
        tempList = tempList.filter(x => accList.findIndex(y => y.Doc_Num === x.Doc_Num) !== -1);
      }

      if(obsSelected !== null){
        tempList = docList.filter(x => x.Doc_Num === obsSelected.Doc_Num);
      }

      if(searchString === ""){
        if(obsSelected !== null){
          return tempList.sort(sortBy("Doc_Status", "-Revision"));
        }else{
          return tempList.sort(sortBy(defaultSort));
        }
      }else{
        return tempList.sort((a,b) => {
          const ja = JSON.stringify(a).toLocaleLowerCase();
          const jb = JSON.stringify(b).toLocaleLowerCase();
          if(ja.includes(searchString.toLocaleLowerCase())){
            return jb.includes(searchString.toLocaleLowerCase())? 
              0
            : 
              -1;
          }else{
            return 1;
          }
        });
      }
    },[docList, searchString, defaultSort, obsSelected, supFilter])

    const useSups = useMemo(() => {
      if(supSelected === null){
        return [];
      }else{
        return attSups.filter(x => x.Doc_Num === supSelected.Doc_Num);
      }
    },[supSelected]);

    const closeModal = () => {
      setIsModalOpen(false);
      setSupSelected(null);
    };

     const useUniSups = useMemo(() => {
        const ret:attachedSupplier[] = [];
        attSups.forEach(x => {
          if(ret.findIndex(y => y.AttachPath === x.AttachPath) === -1){
            ret.push({
              ...x,
              Sup_description: x.Sup_description
                .replace("ARCH Medical Solutions - ","AMS ")
                .replace("ARCH Medical & Aerospace - ", "AMA ")
                .replace(",", "")
                .replace("Inc", "")
                .replace("technologies", "tech.")
                .replace("Electrolizing Corporation of", "Electo.")
                .replace("Thermal Technologies", "thermo. tech.")
            });
          }else{
            //cont
          }
        });
        return ret;
        //return Array.from(new Set(attSups.map(x => x.AttachPath)))
     }, [attSups]);


  return (
    <div
      className={clsx(
        "relative flex w-full divide-black flex-col overflow-hidden h-screen bg-slate-800"
      )}
    >
      {/* blurring */}

      <SupModal
        isOpen={supSelected !== null}
        closeModal={closeModal}
        items={useSups}
        onSubmit={(x) =>
          supFilter === x ? setSupFilter(null) : setSupFilter(x)
        }
        inSup={supFilter}
      />

      {/*main window*/}
      <div className={clsx("flex flex-col relative overflow-hidden w-full")}>
        {/* top row controls */}
        <div
          className={clsx(
            "flex flex-row sticky top-0 flex-none divide-x divide-black bg-slate-800 overflow-hidden font-semibold border-b border-black px-2.5"
          )}
        >
          <div
            className={clsx(
              "flex flex-row flex-none divide-x divide-black oveflow-hidden"
            )}
          >
            {DocTypes.map((x) => (
              <button
                key={x}
                className={clsx(
                  `py-1 px-2 flex flex-row space-x-1`,
                  sourcePath === x
                    ? "bg-green-300 text-green-800 hover:bg-green-500"
                    : "bg-slate-500 hover:bg-slate-300 text-slate-800"
                )}
                onClick={() => changeDocTypes(x)}
              >
                {x}
              </button>
            ))}
            <div className="flex-none w-2 invisible" />

            <div className="grid grid-rows-2 grid-flow-col divide-black text-xs overflow-x-auto overflow-y-hidden">
              <button
                className={clsx(
                  `px-2 flex flex-row space-x-1 border-b border-r border-black`,
                  supFilter === null
                    ? "bg-green-300 text-green-800 hover:bg-green-500"
                    : "bg-slate-500 hover:bg-slate-300 text-slate-800"
                )}
                onClick={() => setSupFilter(null)}
              >
                All Sites
              </button>
              {useUniSups.map((x) => (
                <button
                  key={x.AttachPath}
                  className={clsx(
                    `px-2 flex flex-row space-x-1 border-b border-r border-black`,
                    supFilter === x.AttachPath
                      ? "bg-green-300 text-green-800 hover:bg-green-500"
                      : "bg-slate-500 hover:bg-slate-300 text-slate-800"
                  )}
                  onClick={() =>
                    supFilter !== x.AttachPath
                      ? setSupFilter(x.AttachPath)
                      : setSupFilter(null)
                  }
                >
                  {x.Sup_description}
                </button>
              ))}
            </div>
          </div>

          <div className="flex-1">
            <p className="invisible">spacer</p>
          </div>

          <div className="flex flex-row space-x-2 p-0.5 overflow-hidden flex-none">
            <input
              type="text"
              className="sm:flex w-72 text-left px-2 shadow-md rounded focus:outline-none"
              value={searchString}
              placeholder="Search by keyword"
              onChange={(e) => setSearchString(e.target.value)}
            />

            <button
              onClick={(e) => setSearchString("")}
              className={clsx(
                "flex flex-none px-0.5 border rounded items-center shadow",
                searchString !== ""
                  ? "bg-red-300 text-red-800 border-red-800 hover:bg-red-500"
                  : "text-white border-white"
              )}
            >
              <BiX size={24} className="text-white" />
            </button>
          </div>
        </div>

        <div
          className={"relative flex flex-col overflow-y-auto scrollbar-hide"}
        >
          {/* header row */}
          <div className="sticky top-0 mb-1 px-4 flex flex-row space-x-2 items-center align-center shadow-md flex-none overflow-hidden bg-slate-700 h-8 text-slate-300 text-xs font-medium py-1 border-b border-sky-800">
            <label
              className={clsx(
                "flex-none text-left w-1/4 overflow-hidden truncate text-lg font-semibold cursor-pointer",
                defaultSort === "Doc_Name" ? "text-green-500" : ""
              )}
              onClick={() => {
                setDefaultSort("Doc_Name");
                setSearchString("");
              }}
            >
              {obsSelected === null
                ? "Name"
                : `${obsSelected.Doc_Num} Revisions`}
            </label>

            <p
              className={clsx(
                "flex-none text-left w-12 overflow-hidden truncate cursor-pointer",
                defaultSort === "Doc_Num" ? "text-green-500" : ""
              )}
              onClick={() => {
                setDefaultSort("Doc_Num");
                setSearchString("");
              }}
            >
              Doc#
            </p>

            <p
              className={clsx(
                "flex-none text-left w-12 overflow-hidden truncate cursor-pointer",
                defaultSort === "Revision" ? "text-green-500" : ""
              )}
              onClick={() => {
                setDefaultSort("Revision");
                setSearchString("");
              }}
            >
              Rev
            </p>

            <p
              className={clsx(
                "flex-none text-left w-12 overflow-hidden truncate cursor-pointer hidden",
                defaultSort === "Doc_Name" ? "text-green-500" : ""
              )}
              onClick={() => {
                setDefaultSort("Doc_Num");
                setSearchString("");
              }}
            >
              {/* {new Date(x.Release_Date).toLocaleDateString()} */}
              Cont.
            </p>

            <p
              className={clsx(
                "flex-1 text-left overflow-hidden truncate ellipsis",
                defaultSort === "Notes" ? "text-green-500" : ""
              )}
              onClick={() => {
                setDefaultSort("Notes");
                setSearchString("");
              }}
            >
              Description
            </p>

            <p
              className={clsx(
                "cursor-pointer text-center overflow-hidden h-full w-12 flex-none px-2 py-1 rounded-md bg-green-800 hover:bg-green-500 hover:text-green-800 text-green-300 border-green-800",
              )}
              onClick={() => {
                setSupFilter(null);
              }}
            >
              {supFilter === null? "Sites": "All"}
            </p>
              {/* swap to gray unless filter is active && fix borders && add obs view? */}
            <p
              className={clsx(
                "cursor-pointer text-center overflow-hidden h-full w-12 flex-none px-2 py-1 rounded-md bg-green-800 hover:bg-green-500 hover:text-green-800 text-green-300 border-green-800",
              )}
              onClick={() => {
                setObsSelected(null);
              }}
            >
              {obsSelected === null ? "OBS." : "All"}
            </p>

            <p
              className={clsx(
                "cursor-pointer text-center overflow-hidden h-full w-[9.5rem] flex-none px-2 py-1 rounded-md bg-green-800 hover:bg-green-500 hover:text-green-800 text-green-300 border-green-800",
              )}
              onClick={() => {
                setObsSelected(null);
              }}
            >
              Doc. Access
            </p>

          </div>

          {/* doc rows */}
          {useDocs.map((x, i) => (
            <div
              key={x.Doc_ID}
              className={clsx(
                "mx-2 flex flex-row space-x-2 items-center align-center shadow-md flex-none overflow-hidden h-8 text-xs font-medium py-1 px-2 border-b border-sky-800",
                x.File_Path.includes("ERROR") ? "bg-red-950" : "bg-slate-700",
                x.Doc_Status === "Active"
                  ? "text-slate-300"
                  : "text-yellow-300 text-opacity-60"
              )}
            >
              <label className="flex space-x-1 flex-row flex-none text-left w-1/4 overflow-hidden truncate text-lg font-semibold ">
                <p>{x.Doc_Status === "Active" ? "" : "(OBS)"}</p>
                <p>{x.Doc_Name}</p>
              </label>

              <p className="flex-none text-left w-12 overflow-hidden truncate">
                {x.Doc_Num}
              </p>

              <p className="flex-none text-left w-12 overflow-hidden truncate">
                {x.Revision}
              </p>

              <p className="flex-none text-left w-12 overflow-hidden truncate text-slate-500 hidden">
                {/* {new Date(x.Release_Date).toLocaleDateString()} */}
                TBD
              </p>

              <p
                className={clsx(
                  "flex-1 text-left overflow-hidden truncate ellipsis",
                  !x.Notes ? "text-slate-500" : ""
                )}
              >
                {abreivateNotes(x.Notes)}
              </p>

              <button
                disabled={
                  x.File_Path.includes("ERROR") ||
                  x.RevisionNotes.split(",")[1] !== "Y"
                }
                className={clsx(
                  "flex flex-row px-2 h-full w-12 flex-none overflow-hidden rounded-md border shadow-md",
                  x.RevisionNotes.split(",")[1] === "Y"
                    ? "bg-sky-800 hover:bg-sky-500 hover:text-sky-800 text-sky-300 border-sky-800"
                    : "border-gray-500 text-gray-500"
                )}
                onClick={() =>
                  supSelected === null
                    ? setSupSelected(x)
                    : setSupSelected(null)
                }
              >
                <BiSolidBusiness size={24} />
              </button>

              <button
                disabled={
                  x.File_Path.includes("ERROR") ||
                  x.RevisionNotes.split(",")[0] !== "Y"
                }
                className={clsx(
                  "px-2 h-full w-12 flex flex-col flex-none overflow-hidden rounded-md border shadow-md items-center align-center justify-center align-items-center",
                  obsSelected !== null
                    ? "bg-green-800 hover:bg-green-500 hover:text-green-800 text-green-300 border-green-800"
                    : x.RevisionNotes.split(",")[0] === "Y"
                    ? "bg-sky-800 hover:bg-sky-500 hover:text-sky-800 text-sky-300 border-sky-800"
                    : "border-gray-500 text-gray-500"
                )}
                onClick={() =>
                  obsSelected === null
                    ? setObsSelected(x)
                    : setObsSelected(null)
                }
              >
                {obsSelected !== null ? (
                  <BiArrowBack className="flex" size={24} />
                ) : (
                  <BiHistory className="flex" size={24} />
                )}
              </button>

              <button
                disabled={x.File_Path.includes("ERROR")}
                className={clsx(
                  "px-2 h-full w-12 flex-none overflow-hidden rounded-md border shadow-md",
                  x.File_Path.includes("ERROR")
                    ? "bg-red-800 hover:bg-red-500 hover:text-red-800 text-red-300 border-red-800"
                    : "bg-sky-800 hover:bg-sky-500 hover:text-sky-800 text-sky-300 border-sky-800"
                )}
                onClick={() => getAttachedDoc(x)}
              >
                Open
              </button>
              <button
                disabled={x.File_Path.includes("ERROR")}
                className={clsx(
                  "px-2 h-full w-24 flex-none overflow-hidden rounded-md border shadow-md",
                  x.File_Path.includes("ERROR")
                    ? "bg-red-800 hover:bg-red-500 hover:text-red-800 text-red-300 border-red-800"
                    : "bg-sky-800 hover:bg-sky-500 hover:text-sky-800 text-sky-300 border-sky-800"
                )}
                onClick={() => downloadDocument(x)}
              >
                Download
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DocBox;
