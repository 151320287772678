import React from "react";
import { MODE_D, AUTHTOKEN, UserAccount } from "../Decoders/Decoders";
import ProcedureList from "../../Pages/Private/ProcedureList";
import WebMonitor from "../../Pages/internalTools/WebMonitor";
import Employees from "../../Pages/internalTools/employees";
import styled from "styled-components";
import Welcome from "../../Pages/public/Welcome";
import BioPage from "../../Pages/public/BioPage";
import DocBox from "../../Pages/Private/docBox";
//global constants/ things that do not change through the life of the application but may be accessed by multiple components

export const devMode = true; //allows you to add custom functions that only work when not in production, switch to false before deployment
export const connectionStringBase = ""

export const guestToken: AUTHTOKEN = {
  userInfo: {
    status: 404,
    isAuthenticated: false,
    isLibraryAdmin: false,
    username: "Guest",
  },
  permissionLevel: 0,
};

export const guestAccount: UserAccount = {
  ID: "INVALID",
  FIRST_NAME: null,
  LAST_NAME: null,
  PHONE: null,
  DEPARTMENT_ID: null,
  ACTIVE: null,
  SHIFT_ID: null,
  EMAIL_ADDR: null,
  MANAGER: null,
  PERMISSIONS: null,
  PREFRENCES: null,
  SIGNITURE: null,
  PIN: null,
};

// export const userList: { userName: string, permissionLevel: number }[] = [
//   { userName: 'rittir.frankowski', permissionLevel: 10 }
// ]

//its flex-1 or flex-none 
export const safePage: string = "/"; //use to change the saftey page on error screen
export const defaultPage: string = "/Documents/:DocumentType";
export const Webpages: MODE_D[] = [
  {
    ID: 1,
    permissionLevel: 0,
    mode: "CRittirsCreations",
    path: "/",
    element: <Welcome />,
    //element: <DashBoard />,
    active: true,
  },
  {
    ID: 2,
    permissionLevel: 0,
    mode: "Rittir.Frankowski",
    path: "/Bio",
    element: <BioPage />,
    //element: <DashBoard />,
    active: true,
  },
  {
    ID: 3,
    permissionLevel: 0,
    group: "Tools",
    mode: "Locals only",
    path: "/WebMonitor",
    element: <WebMonitor />,
    active: false,
  },
  {
    ID: 4,
    permissionLevel: 0,
    group: "Tools",
    mode: "Emp. Acct. Management",
    path: "/EmployeeAcounts",
    element: <Employees />,
    active: false,
  },
  {
    ID: 5,
    permissionLevel: 0,
    group: "Downloads",
    mode: "3D Models",
    path: "/Downloads/3DModels",
    element: <DocBox />,
    active: true,
  },
  {
    ID: 6,
    permissionLevel: 0,
    group: "Downloads",
    mode: "1D Documents",
    path: "/Downloads/Documents",
    element: <DocBox />,
    active: true,
  },
  {
    ID: 7,
    permissionLevel: 0,
    group: "Downloads",
    mode: "2D Graphics",
    path: "/Downloads/2DGraphics",
    element: <DocBox />,
    active: true,
  },
];

export const formInputTypes = () => {};

export const RTStyles = styled.div`
  .table {
    border: 1px solid #ddd;

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 5px;
      border-bottom: 1px solid #ddd;
      border-right: 1px solid #ddd;
      overflow: hidden;

      :last-child {
        border-right: 0;
      }
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        top: 0;
        box-shadow: 0px 3px 3px #ccc;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 3px #ccc;
      }

      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
`;
